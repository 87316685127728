
a{
  text-decoration: none;
}
li{
  list-style: none;
}

/* Navbar */
.nav-wrapper{
  background: transparent;

}
.nav{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.nav-menu{
  display: flex;
  align-items: center;
  gap: 1rem;
  font-weight: bold;
}
.nav-link{
  color: black;
  cursor: pointer;
}
.nav-link:hover{
color: skyblue;
}

.toggler-icon{
  cursor: pointer;
  display: none;
}
.toggler-icon div{
width: 25px;
height: 2px;
background: black;
margin: 5px 0px;
transition: 0.4s ease;
font-weight: bold;
}

@media screen and (max-width: 600px) {
  
  .toggler-icon{
    display: block;
    z-index: 30;
  }
  
  .nav-menu{
    position: fixed;
    background: rgba(245, 245, 220, 0.976);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    width: 16rem;
    height: 40rem;
    right: 0;
    border-radius: 5px;
    transform: translateX(100%);
    transition: 0.8s ease;
    z-index: 20;

    
  }
  .nav-link{
    display: block;
    color: black;
    font-size: 1.2rem;
   
  }
}

@media screen and (max-width: 320px) {
  .toggler-icon{
  display: block;
  }
 
}


.nav-collapse{
  transform: translateX(0%);
}

/* Toggle icon */
.toggle .line-1{
  transform: translate(-5px, 7px) rotate(-45deg);
}
.toggle .line-2{
  opacity: 0;
}
.toggle .line-3{
  transform: translate(-5px, -7px) rotate(45deg);
}

